import React from 'react';
import './HalfHeightBlock.scss';

// ! Hmmm could be moved into a new folder called 'layout'?
const HalfHeightBlock = ({ children }) => {
  return (
    <div className="half_height_block">
      <div className="half_height_block__wrapper">
        {children}
      </div>
    </div>
   );
}

export default HalfHeightBlock;