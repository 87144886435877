import React from 'react';
import Layout from './src/components/Layout/Layout';
import Provider from './src/utils/context/globalContext';
import { LocationContext } from './src/utils/context/locationContext';
import './src/lib/styles/_reboot.scss';
import './src/lib/styles/_global.scss';


export const wrapPageElement = ({ element, props }) => {
  return (
    <LocationContext.Provider value={props.location}>
      <Layout location={props.location.pathname}>{element}</Layout>
    </LocationContext.Provider>
  );
};

export const wrapRootElement = Provider;