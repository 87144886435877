import React from 'react';
import MiniNavigation from '../MiniNavigation/MiniNavigation';
import './ContactDetails.scss';
import './ContactLink/ContactLink.scss'; // ! TEMP


// ! Messy - Needs cleaning up.
const ContactDetails = ({ data: { socialInstagram, socialLinkedin, phoneNumber, location, enquiriesCareers, enquiriesEmail }, miniNav = true}) => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="contact_details">
      {miniNav && <MiniNavigation heading="Hello" position="flex" />}
      <div className="contact_details__content">
        {/* TODO: convert these into a reusable component later*/}

        <div className="contact_details__row">
          {location.title &&<div className="contact_link contact_details__contact">
            <span>Studio</span>
              <a className="large" href={location.url} target={location.target} rel="noopener noreferrer">
                20 Vale St<br/> North Melbourne<br/>VIC 3051. Australia
              </a>
          </div>}
        </div>

        <div className="contact_details__row">
          <div className="contact_link contact_details__contact contact_link--phone">
            <span>Call</span>
            {phoneNumber.title && <a className="medium" href={phoneNumber.url}>{phoneNumber.title}</a>}
          </div>

          <div className="contact_link contact_details__contact contact_link--email">
            <span>Email</span>
            {enquiriesEmail.title && <a className="medium" href={enquiriesEmail.url}>{enquiriesEmail.title}</a>}
            {enquiriesCareers.title && <a className="medium" href={enquiriesCareers.url}>{enquiriesCareers.title}</a>}

          </div>

          <div className="contact_link contact_details__contact contact_link--social">
            <span>Follow</span>
            {socialInstagram.title && <a className="medium" href={socialInstagram.url} target="_blank" rel="noreferrer">{socialInstagram.title}</a>}
            {socialLinkedin.title && <a className="medium" href={socialLinkedin.url} target="_blank" rel="noreferrer">{socialLinkedin.title}</a>}
          </div>
        </div>
        <span className="contact_details__copyright small">
          &copy;{` Seesaw / ${currentYear} All Rights Reserved`}
        </span>
      </div>
    </div>
   );
}

export default ContactDetails;