import React, { useEffect } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
// import Nav from '../Nav/Nav';
// import { Helmet } from 'react-helmet';
import Scroll from '../LocomotiveScroll/LocomotiveScroll';
import PopupBlock from '../PopupBlock/PopupBlock'
import ContactDetails from '../ContactDetails/ContactDetails'
import HalfHeightBlock from '../HalfHeightBlock/HalfHeightBlock'
import MiniNavigation from '../MiniNavigation/MiniNavigation'
// import LogoLists from '../LogoLists/LogoLists';
import BCorpLogo from '../../images/svg/bcorp.svg';
import Logo from '../Logo/Logo';
import Cursor from '../Cursor/Cursor';
import { CursorContextProvider } from '../../utils/context/cursorContext';
import './Layout.scss';

const Layout = ({ children, location }) => {
  const currentYear = new Date().getFullYear();
  const slug = location.replace(/\//g, '') || 'home';

  function webHeight() {
    const doc = document.documentElement;
    doc.style.setProperty('--vp-height', `${window.innerHeight}px`);
  }
  const { wp: { themeOptions: { themeOptions } }, allWpMenu: { nodes }} = useStaticQuery(graphql`
    query ThemeOptionsQuery {
      wp {
        themeOptions {
          themeOptions {
            socialInstagram {
              target
              title
              url
            }
            socialLinkedin {
              url
              title
              target
            }
            phoneNumber {
              url
              title
              target
            }
            location {
              url
              title
              target
            }
            info
            enquiriesEmail {
              url
              title
              target
            }
            enquiriesCareers {
              target
              title
              url
            }
            acknowledgement
          }
        }
      }
      allWpMenu {
        nodes {
          name
          menuItems {
            nodes {
              label
              url
            }
          }
        }
      }
    }
  `);

  const menuNav = nodes.filter(node => node.name === 'Nav Menu')[0].menuItems.nodes;

  useEffect(() => {
    window.addEventListener('resize', webHeight);
    webHeight();
  },[]);
  return (
    <>
      {/* <Helmet>
        <meta charSet="utf-8" />
        <title>Seesaw Studio</title>
      </Helmet> */}
      {/* <Nav position="top" /> */}
      <CursorContextProvider>
        <Cursor />
        <Scroll callbacks={window?.location}/>
        <main className={slug} data-scroll-container>
          {children}
        </main>
        {/* Create a container component to house the content in PopupBlock? */}

        <>
          <PopupBlock position="right" linkPosition="right" direction="right" bgColour="#c19983" linkText="Hello" slug={slug}>
            <Logo logoText="S" url="/" />
            <ContactDetails data={themeOptions} />
          </PopupBlock>

          <PopupBlock position="right" linkPosition="left" direction="right" bgColour="#afa69e" linkText="Purpose" slug={slug} linkUrl="/purpose">
            <Logo logoText="S" url="/" />
            <HalfHeightBlock>
              <MiniNavigation heading="Purpose" position="flex" />
              {/* !TEMP */}
              <div className="flex-wrapper">
                <p className="small" dangerouslySetInnerHTML={{__html: themeOptions.info}} />
                {/* <LogoLists /> */}
              </div>
            </HalfHeightBlock>
          </PopupBlock>
        </>


        <PopupBlock position="right" direction="bottom" bgColour="#c19983" linkText="Purpose" burger={true}>
          <Logo logoText="S" url="/" />
          <HalfHeightBlock>
            <MiniNavigation heading="Menu" position="flex" />
            <div className="flex-wrapper">
              <ul>
                {menuNav && menuNav.map((item, index) => {
                  let { label, url } = item;
                  return (
                    <li key={index}>
                      <Link to={url} className="large">{label}</Link>
                    </li>
                  );
                })}
              </ul>
              <div className="menu-footer">
                 <div className="menu-footer__column">
                 {themeOptions.acknowledgement ?<span className="small acknowledgement">{themeOptions.acknowledgement}</span> : ''}
                  <span className="small">&copy;{` Seesaw / ${currentYear} All Rights Reserved`}</span>
                </div>

                <div className="menu-footer__column">
                  <BCorpLogo />
                </div>
              </div>
            </div>
          </HalfHeightBlock>
        </PopupBlock>
      </CursorContextProvider>
    </>
   );
}



export default Layout;

