import { useContext, useCallback } from 'react';
import { CursorContext } from '../context/cursorContext';

export const useMouseHandlers = (options = false) => {
	const [, setCursor, ,setDirection] = useContext(CursorContext);

	const toggleCursor = (state) => {
		if(state !== false) {
			setCursor({ active: true });
		} else {
			setCursor({ active: false });
		}
	};

	const updateDirection = (direction) => {
		setDirection(direction);
	};

	const onMouseEnter = useCallback(event => {
		if (options.onMouseEnter) {
			options.onMouseEnter(event);
		}

		if(options === false) {
			toggleCursor(false);

		} else if(options) {
			updateDirection(options);
			toggleCursor(true);

		} else if(options.length === 0) {
			toggleCursor(false);
		}
	});

	const onMouseLeave = useCallback(event => {
		if (options.onMouseLeave) {
			options.onMouseLeave(event);
		}

		if(!options) {
			toggleCursor(!options);

		} else {
			updateDirection(options);
		}
	});



	return { onMouseEnter, onMouseLeave };
};