import { useState, useEffect } from 'react';

const useMousePosition = () => {
	const [position, setPosition] = useState({
		clientX: 0,
		clientY: 0,
	});

	// !!! Performance impact.
	const updatePosition = event => {
		const { clientX, clientY } = event;
		const element = event?.path?.find(element => element?.classList?.contains('section'));
		const parallaxOffsetY = element ? element?.querySelector('[data-scroll-speed]')?.getBoundingClientRect()?.top : 0;
		let trueClientY = clientY - parallaxOffsetY; // this value is for the modules that have data-scroll-speed set.

		if(isNaN(trueClientY)) {
			trueClientY = clientY;
		}

		setPosition({
			clientX,
			clientY,
			trueClientY
		});
	};

	useEffect(() => {
		document.addEventListener('mousemove', updatePosition, false);
		document.addEventListener('mouseenter', updatePosition, false);

		return () => {
			document.removeEventListener('mousemove', updatePosition);
			document.removeEventListener('mouseenter', updatePosition);
		};
	}, []);

	return position;
};

export default useMousePosition;