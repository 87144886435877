import { navigate } from 'gatsby';
import React, { useState, useEffect } from 'react';
import { StateContext } from '../../utils/context/stateContext';
import { useMouseHandlers } from '../../utils/hooks/useMouseHandlers';
import './PopupBlock.scss';

const PopupBlock = ({ children, position = 'left', linkPosition = null, bgColour, linkText, burger = false, slug, linkUrl = '' }) => {
  const [showNav, setShowNav] = useState(true);
  const [show, setShow] = useState(false);
  const mouseHandlers = useMouseHandlers();

  // * Jank setup for the 'intro' page...
  function toggleHeroText(e) {
    const headingLinks = document.querySelectorAll('.minimal-hero__heading--link span');
    if(e.target.classList.contains('right') && headingLinks.length) {
      headingLinks[1].classList.add('hide');
    } else if(headingLinks.length) {
      headingLinks[0].classList.add('hide');
    }
  }

  // * Jank setup for the 'intro' page...
  useEffect(() => {
    const headingLinks = document.querySelectorAll('.minimal-hero__heading--link span');

    if(!show && headingLinks.length) {
      headingLinks[0].classList.remove('hide');
      headingLinks[1].classList.remove('hide');
    }

  },[show]);

  useEffect(() => {
    if(burger) {
      const menu = document.querySelectorAll('.popup_block.menu ul li a');

      if(menu) {
        menu.forEach((item, i) => {
          if(!item.hasEventListener) {
            item.addEventListener('click', (e) => {
              if(item.href.includes('#')) {
                e.preventDefault();
                setShow(false);
                document.querySelector(item.hash).click();
              } else {
                setShow(false);
              }
            });
          }
        });
      }
    }
  },[]);

  return (
    <StateContext.Provider value={{ show: show, toggleState: setShow }}>
      <section className={`popup_block${burger ? ' menu' : ''} ${position}${show ? ' show' : ''}`} style={{backgroundColor: bgColour}} {...mouseHandlers} data-scroll-sticky data-scroll-target="main[data-scroll-container='true']">
        <div className={`blank ${position}`} onClick={() => setShow(!show)}></div>
        {children}
      </section>
      {burger ?
      <div className="burger" onClick={() => {setShow(!show)}} {...mouseHandlers}>
        <span className={`burger__slice${show ? ' active' : ''}`}>—</span>
      </div>
      : <a id={`${linkText?.toLowerCase()}`} className={`nav_link nav_link--fixed ${linkPosition ? linkPosition : position}${!showNav ? ' hide' : ''}${slug === 'home' ? ' hide' : ''}`} onClick={(e) => {linkUrl ? navigate(linkUrl) : setShow(!show); toggleHeroText(e);}} {...mouseHandlers}>{linkText}</a>}

    </StateContext.Provider>
   );
}

export default PopupBlock;