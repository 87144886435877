import React, { useState, createContext } from 'react';

export const GlobalContext = createContext(null);

const Provider = ({ children }) => {
  const [globalState, setGlobalState] = useState({
  });

  return (
    <GlobalContext.Provider value={{
      globalState,
      updateGlobalState: setGlobalState
    }}>
      {children}
    </GlobalContext.Provider>
   );
}


export default ({ element }) => (
  <Provider>
    {element}
  </Provider>
)