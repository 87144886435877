import React, { useRef, useContext, useEffect, useState } from 'react';
import getMousePosition from "../../utils/hooks/getMousePosition";
import { CursorContext } from '../../utils/context/cursorContext';
import CursorSVG from '../../images/svg/cursor.svg';
import './Cursor.scss';


const Cursor = () => {
  const cursorRef = useRef(null);
  const [cursor, ,direction] = useContext(CursorContext);
  const [isVisible, setIsVisible] = useState(false);
  const { clientX, clientY } = getMousePosition();

  useEffect(() => {
    const handleMouseEnter = () => setIsVisible(true);
    const handleMouseLeave = () => setIsVisible(false);

    document.body.addEventListener('mouseenter', handleMouseEnter);
    document.body.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      document.body.removeEventListener('mouseenter', handleMouseEnter);
      document.body.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, [cursor, direction]);

  useEffect(() => {
    if(isVisible) {
      cursorRef.current.style.opacity = 1;
    }
  },[isVisible]);
  return (
    <div ref={cursorRef} className={`cursor cursor--${direction}`} style={{ left: clientX, top: clientY }}>
      <CursorSVG style={{ opacity: isVisible && cursor.active ? '1' : '0' }} />
    </div>
   );
}

export default Cursor;