import { useEffect } from "react";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
// We are excluding this from loading at build time in gatsby-node.js
import LocomotiveScroll from "locomotive-scroll";

gsap.registerPlugin(ScrollTrigger);

const Scroll = (callbacks) => {
  useEffect(() => {
    let locomotiveScroll;
    locomotiveScroll = new LocomotiveScroll({
      el: document.querySelector("[data-scroll-container]"),
      smooth: true,
      class: "is-inview",
      tablet: {
        smooth: true,
        breakpoint: 0,
        multiplier: 0,
      },
      mobile: {
        smooth: true,
        breakpoint: 0,
        multiplier: 0,
      },
    });

    // Exposing to the global scope for ease of use.
    window.scroll = locomotiveScroll;

    locomotiveScroll.on("scroll", (func) => {
      ScrollTrigger.update();

      const progress = func.scroll.y / func.limit.y;

      if (progress >= 0.001 && progress < 1) {
        document.body.classList.add("scrolled");
      } else {
        document.body.classList.remove("scrolled");
      }

      if (progress >= 0.001) {
        document.body.classList.add("interacted");
      } else {
        document.body.classList.remove("interacted");
      }

      document.documentElement.setAttribute("data-direction", func.direction);

      const section = Object.keys(func.currentElements).find((key) => {
        return func.currentElements[key].section.el.classList.contains(
          "section"
        );
      });

      const lastSection = Object.keys(func.currentElements)
        .reverse()
        .find((key) => {
          return (
            func.currentElements[key].section.el.classList.contains("footer") ||
            func.currentElements[key].section.el.classList.contains("next_page")
          );
        });

      if (progress >= 0.9 && lastSection) {
        if (
          func.currentElements[lastSection].section.el.getAttribute("data-bg")
        ) {
          document.body.style.setProperty(
            "background-color",
            func.currentElements[lastSection].section.el.getAttribute("data-bg")
          );
        }
      } else {
        const bg =
          func.currentElements[section]?.section?.el?.getAttribute("data-bg") ||
          "#f2f3f0";
        document.body.style.setProperty("background-color", bg);
      }
    });

    window.addEventListener("resize", () => {
      ScrollTrigger.refresh();
      locomotiveScroll.update();
    });

    // ! Jank, prevents the custom cursor from showing up as we CAN'T access it.
    document
      .querySelector(".c-scrollbar_thumb")
      .addEventListener("mousemove", () => {
        document.querySelector(".cursor svg").style.opacity = 0;
      });

    const bg =
      document.querySelector("section[data-bg]")?.getAttribute("data-bg") ||
      "#f2f3f0";
    document.body.style.setProperty("background-color", bg);

    setTimeout(() => {
      document.body.classList.add("init");
    }, 1000);
    setTimeout(() => {
      locomotiveScroll.update();
    }, 2000);

    ScrollTrigger.scrollerProxy("main[data-scroll-container]", {
      scrollTop(value) {
        return arguments.length
          ? locomotiveScroll.scrollTo(value, 0, 0)
          : locomotiveScroll.scroll.instance.scroll.y;
      }, // we don't have to define a scrollLeft because we're only scrolling vertically.
      getBoundingClientRect() {
        return {
          top: 0,
          left: 0,
          width: window.innerWidth,
          height: window.innerHeight,
        };
      },
      // LocomotiveScroll handles things completely differently on mobile devices - it doesn't even transform the container at all! So to get the correct behavior and avoid jitters, we should pin things with position: fixed on mobile. We sense it by checking to see if there's a transform applied to the container (the LocomotiveScroll-controlled element).
      pinType: document.querySelector("main[data-scroll-container]").style
        .transform
        ? "transform"
        : "fixed",
    });

    // locomotiveScroll.destroy()

    // setTimeout(function(){ locomotiveScroll.init() }, 100);
    ScrollTrigger.addEventListener("refresh", () => locomotiveScroll.update());

    ScrollTrigger.refresh();

    return () => {
      if (locomotiveScroll) locomotiveScroll.destroy();
    };
  }, [callbacks]);

  return null;
};

export default Scroll;
